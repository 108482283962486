<template>
  <div>
    <template v-if="isLoading">
      <div style="height: 100%">
        <loader size="xxs" :loader-image="false" />
      </div>
    </template>
    <template v-else>
      <div class="flex flex-col justify-start gap-10">
        <template>
          <div class="flex justify-start items-center gap-0 mt-5">
            <back-button label="Back" @onClick="$router.back()" variant="secondary" />
            <div class="flex justify-start items-center gap-5 border-l border-jet pl-3">
              <h1 class="text-xl text-left font-extrabold">Delegation Details</h1>
              <breadcrumb :items="breadcrumbs" />
            </div>
          </div>
        </template>
        <template>
          <div class="flex flex-col justify-start gap-5 px-3">
            <card class="p-3">
              <div class="flex flex-col justify-start gap-3 border border-dashed rounded p-3">
                <h2 class="text-lg font-bold text-darkPurple">Delegation Management Details</h2>
                <div class="flex flex-col justify-start gap-4">
                  <card class="w-full flex flex-col justify-start items-start gap-1 p-3">
                    <p class="text-xs font-black uppercase text-blueCrayola">Delegated Designation</p>
                    <p class="font-semibold text-jet text-base capitalize">
                      <span v-if="delegatorInfo.userDesignation">{{ delegatorInfo.userDesignation.name }}</span>
                      <span v-else-if="delegatorInfo.name">{{ delegatorInfo.name }}</span>
                      <span v-else>-</span>
                    </p>
                  </card>
                  <div class="flex flex-row justify-evenly items-center gap-4">
                    <card class="w-full flex flex-col justify-start items-start gap-1 p-3">
                      <p class="text-xs font-black uppercase text-romanSilver">Delegatee</p>
                      <div>
                        <p class="font-semibold text-jet text-base capitalize">
                        {{ delegateeInfo.fname }} {{ delegateeInfo.lname }}
                      </p>
                      </div>
                    </card>
                    <card class="w-full flex flex-col justify-start items-start gap-1 p-3">
                      <p class="text-xs font-black uppercase text-romanSilver">Function</p>
                      <p class="font-semibold text-jet text-base capitalize">
                        <span v-if="delegateeInfo.orgFunction">
                          {{ delegateeInfo.orgFunction.name }}
                        </span>
                        <span v-else>-</span>
                      </p>
                    </card>
                    <card class="w-full flex flex-col justify-start items-start gap-1 p-3">
                      <p class="text-xs font-black uppercase text-romanSilver">Delegation Period</p>
                      <p class="font-semibold text-jet text-base capitalize">
                        <span v-if="delegatedEmployee.delegationPeriodStart || delegatedEmployee.delegationPeriodEnd">
                          {{ $DATEFORMAT(new Date(delegatedEmployee.delegationPeriodStart),"MMM dd, yyyy") }} -
                          {{ $DATEFORMAT(new Date(delegatedEmployee.delegationPeriodEnd),"MMM dd, yyyy") }}
                        </span>
                        <span v-else>-</span>
                      </p>
                    </card>
                  </div>
                  <div class="flex flex-row justify-between items-center gap-4">
                    <card class="w-full flex flex-col justify-start items-start gap-1 p-3">
                      <p class="text-xs font-black uppercase text-romanSilver">Delegated Tasks</p>
                      <template v-if="delegatedEmployee.delegatedTask">
                        <template v-if="delegatedEmployee.delegatedTask.length">
                          <div class="flex justify-between w-full">
                            <div class="flex flex-row justify-start items-center gap-3">
                              <p class="font-semibold text-jet text-base capitalize">
                                {{ delegatedEmployee.delegatedTask[0].specify }}
                              </p>
                              <small class="text-flame text-xs font-bold">
                                +{{ delegatedEmployee.delegatedTask.length - 1 }}
                              </small>
                            </div>
                            <div class="cursor-pointer" @click="isOpenDelegatedTasks = true">
                              <small class="text-blueCrayola cursor-pointer text-xs">View Trail</small>
                            </div>
                          </div>
                        </template>
                        <p v-else class="font-semibold text-jet text-base capitalize">
                          No Delegated Tasks
                        </p>
                      </template>
                    </card>
                    <card class="w-full flex flex-col justify-start items-start gap-1 p-3">
                      <p class="text-xs font-black uppercase text-romanSilver">Reason for Delegation</p>
                      <p class="font-semibold text-jet text-base capitalize">
                        <span v-if="delegatedEmployee.delegationReason">
                          <span v-if="delegatedEmployee.delegationReason === 'vacant'">Vacant Position</span>
                          <span v-if="delegatedEmployee.delegationReason === 'unavailable'">Unavailable Employee</span>
                          <span v-if="delegatedEmployee.delegationReason === 'support'">Support</span>
                        </span>
                        <span v-else>-</span>
                      </p>
                    </card>
                  </div>
                </div>
              </div>
            </card>
            <card class="p-3 pt-7">
              <div class="border-t border-jet">
                <div class="grid grid-cols-3 gap-4 py-5">
                  <div class="flex flex-col justify-start gap-2">
                    <p class="text-base font-semibold">Submitted By:</p>
                    <div class="flex flex-row justify-start items-center gap-2">
                      <div>
                        <div v-if="submittedBy.photo" class="flex justify-center items-center w-24 h-28">
                          <img :src="submittedBy.photo" class="w-24 h-28 rounded" alt="profile photo" />
                        </div>
                        <div v-else class="flex justify-center items-center w-24 h-28 rounded border">
                          <span class="text-blueCrayola text-center font-semibold text-4xl">
                            {{ $getInitials(`${submittedBy.fname} ${submittedBy.lname}`) }}
                          </span>
                        </div>
                      </div>
                      <div class="flex flex-col justify-start gap-2">
                        <p class="text-base text-optimaBlack font-bold">
                          {{ submittedBy.fname }} {{ submittedBy.lname }}
                        </p>
                        <p class="text-lg text-blueCrayola font-bold">
                          <span v-if="submittedBy.userDesignation">
                            {{ submittedBy.userDesignation.name }}
                          </span>
                          <span v-else>-</span>
                        </p>
                      </div>
                      <!-- <div class="flex flex-row justify-start items-center gap-2">
                        <Icon icon-name="icon-message-outline" size="xs" class="" />
                        <p class="text-flame text-sm font-semibold">Send Message</p>
                      </div> -->
                    </div>
                  </div>
                  <div class="flex flex-col justify-start gap-2">
                    <p class="text-base font-semibold">Delegation for:</p>
                    <div class="flex flex-row justify-start items-center gap-2">
                      <div>
                        <div v-if="delegatorInfo.photo" class="flex justify-center items-center w-24 h-28">
                          <img :src="delegatorInfo.photo" class="w-24 h-28 rounded" alt="profile photo" />
                        </div>
                        <div v-else class="flex justify-center items-center w-24 h-28 rounded border">
                          <span class="text-blueCrayola text-center font-semibold text-4xl">
                           <span v-if="delegatorInfo.fname || delegatorInfo.lname">
                              {{ $getInitials(`${delegatorInfo.fname} ${approverInfo.lname}`) }}
                            </span>
                            <span v-else>
                              {{ $getInitials(delegatorInfo.name) }}
                            </span>
                          </span>
                        </div>
                      </div>
                      <div class="w-full flex flex-col justify-start gap-2">
                        <p class="text-base text-optimaBlack font-bold">
                          <span v-if="delegatorInfo.fname || delegatorInfo.lname">
                            {{ delegatorInfo.fname }} {{ delegatorInfo.lname }}
                          </span>
                          <span v-else>{{ delegatorInfo.name }}</span>
                        </p>
                        <div class="w-full flex flex-col justify-start gap-2">
                          <div class="w-full flex flex-row justify-start items-center gap-2">
                            <p class="w-2/5 font-bold uppercase text-romanSilver text-10">Designation:</p>
                            <p class="w-3/5 font-semibold uppercase text-xs text-jet">
                              <span v-if="delegatorInfo.userDesignation">
                                {{ delegatorInfo.userDesignation.name }}
                              </span>
                               <span v-else-if="delegatorInfo.name">
                                {{ delegatorInfo.name }}
                              </span>
                              <span v-else>-</span>
                            </p>
                          </div>
                          <div class="w-full flex flex-row justify-start items-center gap-2">
                            <p class="w-2/5 font-bold uppercase text-romanSilver text-10">Function:</p>
                            <p class="w-3/5 font-semibold uppercase text-xs text-jet">
                              <span v-if="delegatorInfo.orgFunction">
                                {{ delegatorInfo.orgFunction.name }}
                              </span>
                              <span v-else>-</span>
                            </p>
                          </div>
                          <div class="w-full flex flex-row justify-start items-center gap-2">
                            <p class="w-2/5 font-bold uppercase text-romanSilver text-10">Office Location:</p>
                            <p class="w-3/5 font-semibold uppercase text-xs text-jet">
                              <span v-if="delegatorInfo.employeeOffice">
                                {{ delegatorInfo.employeeOffice.name }}
                              </span>
                              <span v-else>-</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col justify-start gap-2" v-if="$route.query.slug !== 'history'">
                    <p class="text-base font-semibold">Current Approver:</p>
                    <div class="flex flex-row justify-start items-center gap-2">
                      <div>
                        <div v-if="approverInfo.photo" class="flex justify-center items-center w-24 h-28">
                          <img :src="approverInfo.photo" class="w-24 h-28 rounded" alt="profile photo" />
                        </div>
                        <div v-else class="flex justify-center items-center w-24 h-28 rounded border">
                          <span class="text-blueCrayola text-center font-semibold text-4xl">
                            {{ $getInitials(`${approverInfo.fname} ${approverInfo.lname}`) }}
                          </span>
                        </div>
                      </div>
                      <div class="w-full flex flex-col justify-start gap-2">
                        <p class="text-base text-optimaBlack font-bold">
                          {{ approverInfo.fname }} {{ approverInfo.lname }}
                        </p>
                        <div class="w-full flex flex-col justify-start gap-2">
                          <div class="w-full flex flex-row justify-start items-center gap-2">
                            <p class="w-2/5 font-bold uppercase text-romanSilver text-10">Designation:</p>
                            <p class="w-3/5 font-semibold uppercase text-xs text-jet">
                              <span v-if="approverInfo.userDesignation">
                                {{ approverInfo.userDesignation.name }}
                              </span>
                              <span v-else>---</span>
                            </p>
                          </div>
                          <div class="w-full flex flex-row justify-start items-center gap-2">
                            <p class="w-2/5 font-bold uppercase text-romanSilver text-10">Function:</p>
                            <p class="w-3/5 font-semibold uppercase text-xs text-jet">
                              <span v-if="approverInfo.orgFunction">
                                {{ approverInfo.orgFunction.name }}
                              </span>
                              <span v-else>---</span>
                            </p>
                          </div>
                          <div class="w-full flex flex-row justify-start items-center gap-2">
                            <p class="w-2/5 font-bold uppercase text-romanSilver text-10">Office Location:</p>
                            <p class="w-3/5 font-semibold uppercase text-xs text-jet">
                              <span v-if="approverInfo.employeeOffice">
                                {{ approverInfo.employeeOffice.name }}
                              </span>
                              <span v-else>---</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </card>
            <card class="flex flex-row justify-between items-center gap-5 p-5">
              <div class="w-full flex flex-col justify-start">
                <p class="text-lg font-bold">Approvers ({{ approversList.length }})</p>
                <p class="text-sm">You can quickly approve or dissaprove from here.</p>
                <p class="text-sm">You also see other approvers’ responses and comments within the approval trail.</p>
              </div>
              <div class="w-full flex flex-row justify-between items-center">
                <div class="w-full flex flex-col justify-start gap-2">
                  <div class="flex flex-row justify-start items-center gap-2">
                    <p class="font-bold text-darkPurple text-xl">
                      <span v-if="submittedBy.requests">
                        {{ Math.ceil(submittedBy.requests.approvalPercentage) }}%
                      </span>
                      <span v-else>-</span>
                    </p>
                    <p class="text-romanSilver text-sm">Completed</p>
                  </div>
                  <template v-if="submittedBy.requests">
                    <ProgressBar :value="submittedBy.requests.approvalPercentage" />
                  </template>
                  <p class="font-semibold text-xs text-romanSilver uppercase">
                    <span v-if="submittedBy.requests">
                      {{ submittedBy.requests.approversLeft }} Approvers Left
                    </span>
                    <span v-else>-</span>
                  </p>
                </div>
                <div class="w-full flex justify-end">
                  <Button
                    label="See Comments"
                    variant="secondary"
                    @onClick="isOpen = true"
                    class="button-class btn-border"
                  />
                </div>
              </div>
            </card>
            <template v-if="!$store.getters.hasSubscriptionExpired()">
              <div v-if="approverInfo.userId === $AuthUser.id && $route.query.slug !== 'history'">
                <CTextarea
                  placeholder="Insert Comment Here"
                  :row="8"
                  :height="90"
                  :col="130"
                  v-model="comment"
                />
              </div>
              <div v-if="approverInfo.userId === $AuthUser.id && $route.query.slug !== 'history'">
                <div class="flex flex-row justify-start items-center gap-5">
                  <Button
                    label="Approve"
                    variant="primary btn-bg"
                    @onClick="onApproveRequest"
                    :disabled="isSubmitting"
                    class="button-class"
                  />
                  <Button
                    label="Disapprove"
                    variant="secondary"
                    @onClick="onDisapproveRequest"
                    :disabled="isSubmitting"
                    class="button-class btn-border"
                  />
                </div>
              </div>
            </template>
          </div>
        </template>
      </div>
    </template>
    <template>
      <RightSideBar
        v-if="isOpenDelegatedTasks"
        @close="isOpenDelegatedTasks = false"
        button-class="bg-dynamicBackBtn text-white"
        :close-button="true"
      >
        <template v-slot:title>
          <div class="flex justify-start items-center">
            <back-button @onClick="isOpenDelegatedTasks = false" variant="secondary" />
            <span class="text-darkPurple font-bold text-lg leading-5 p-0 -ml-2">
              Delegated Tasks
            </span>
          </div>
        </template>
        <template v-slot:default>
          <div class="grid grid-cols-2 gap-4">
            <Card v-for="task in delegatedEmployee.delegatedTask" :key="task.id">
              <div class="flex flex-row items-center justify-start gap-2 mx-5 my-3">
                <Icon icon-name="icon-grid" class="text-romanSilver" size="xxs" />
                <h6 class="font-semibold text-xs text-jet capitalize">{{ task.specify }} </h6>
              </div>
            </Card>
          </div>
        </template>
      </RightSideBar>
    </template>
    <template>
      <RightSideBar
        v-if="isOpen"
        @close="isOpen = false"
        close-button
      >
        <template v-slot:title>
          <p class="text-darkPurple">Approvers ({{ approversList.length }})</p>
        </template>
        <template v-slot:subtitle>
          <p class="font-semibold text-darkPurple pb-4">
            See other approvers comments to this request.
          </p>
        </template>
        <div>
          <div v-for="approver in approversList" :key="approver.userId">
            <ApproversCard
              class="flex justify-start gap-2 bg-white pt-2 px-3 even:bg-ghostWhite border-l-4"
              :class="{
                'border-mediumSeaGreen': approver.currentApprover === 'hasApproved',
                'border-carrotOrange': approver.currentApprover === 'active',
                'border-jet': approver.currentApprover === 'inactive',
              }"
            >
              <div class="flex flex-row justify-between items-center w-full">
                <div class="flex flex-row justify-start items-start gap-2">
                  <div>
                    <div v-if="approver.photo" class="flex justify-center items-center w-10 h-10">
                      <img :src="approver.photo" class="w-10 h-10 rounded" alt="profile photo" />
                    </div>
                    <div v-else class="flex justify-center items-center w-10 h-10 rounded border">
                      <span class="text-blueCrayola text-center font-semibold text-4xl">
                        {{ $getInitials(`${approver.fname} ${approver.lname}`) }}
                      </span>
                    </div>
                  </div>
                  <div class="flex flex-col justify-start items-start gap-1">
                    <div class="flex flex-col justify-start items-start">
                      <p class="block text-base">{{ approver.fname }} {{ approver.lname }}</p>
                      <p class="text-romanSilver uppercase text-xs block">
                        <span v-if="approver.userDesignation">
                          {{ approver.userDesignation.name }}
                        </span>
                        <span v-else>-</span>
                      </p>
                      <p class="block text-xs">
                        <span v-if="approver.actionTakenAt">
                          {{ $DATEFORMAT(new Date(approver.actionTakenAt), "MMMM dd, yyyy") }}
                        </span>
                        <span v-else>-</span>
                      </p>
                    </div>
                    <template>
                      <div v-if="approver.comment" class="text-sm text-jet break-words">
                        <p class="font-normal text-romanSilver leading-tight">Comment:</p>
                        <p class="leading-tight"> {{ approver.comment }}</p>
                      </div>
                      <p class="leading-tight" v-else>--No Comment--</p>
                    </template>
                  </div>
                </div>
                <Tag
                  :class="{
                    'approved': approver.currentApprover === 'hasApproved',
                    'pending': approver.currentApprover === 'active',
                    'not-started': approver.currentApprover === 'inactive',
                  }"
                >
                  <span v-if="approver.currentApprover === 'hasApproved'">Approved</span>
                  <span v-if="approver.currentApprover === 'active'">Pending</span>
                  <span v-if="approver.currentApprover === 'inactive'">Pending</span>
                </Tag>
              </div>
            </ApproversCard>
          </div>
        </div>
      </RightSideBar>
    </template>
  </div>
</template>

<script>
import * as _ from "lodash"
import CTextarea from "@scelloo/cloudenly-ui/src/components/text-area"
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button"
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb"
import Button from "@scelloo/cloudenly-ui/src/components/button"
import Card from "@scelloo/cloudenly-ui/src/components/card"
import Icon from "@/components/Icon"
import ProgressBar from "@/components/ProgressBar"
import ApproversCard from "@/components/ApproversCard"
import RightSideBar from "@/components/RightSideBar"
import Tag from "@/components/Tag"

export default {
  components: {
    BackButton,
    Breadcrumb,
    Card,
    Button,
    Icon,
    ProgressBar,
    CTextarea,
    Tag,
    ApproversCard,
    RightSideBar,
  },
  data() {
    return {
      isOpen: false,
      isLoading: true,
      isSubmitting: false,
      comment: '',
      approversList: [],
      approverInfo: {},
      submittedBy: {},
      delegatorInfo: {},
      delegateeInfo: {},
      delegatedEmployee: {},
      isOpenDelegatedTasks: false,
      breadcrumbs: [
        { disabled: false, text: "Approval", href: "approval", id: "Approval" },
        { disabled: false, text: "My Approvals", href: "my approvals", id: "My Approvals" },
        { disabled: false, text: "Delegation Management Details", id: "DelegationManagementDetails" },
      ],
    };
  },
  methods: {
    onApproveRequest() {
      this.isSubmitting = true

      const payload = {
        hasApproved: true,
        userId: this.$AuthUser.id,
        requestId: this.$route.params.id,
        comment: this.comment,
        requestSlug: this.approverInfo.requestSlug,
        whoToNotify: this.delegatedEmployee.delegatee,
        orgId: this.$orgId
      }

      this.$_respondApprovalRequest(payload).then(({ data }) => {
        this.$toasted.success(data.message, { duration: 3000 })
        this.$router.back({ name: "MyApprovals" })
        this.isSubmitting = false
      }).catch((error) => {
        this.$toasted.error(error.message, { duration: 3000, })
        this.isSubmitting = false
      })
    },

    onDisapproveRequest() {
      this.isSubmitting = true

      const payload = {
        hasApproved: false,
        userId: this.$AuthUser.id,
        requestId: this.$route.params.id,
        comment: this.comment,
        requestSlug: this.approverInfo.requestSlug,
        whoToNotify: this.delegatedEmployee.delegatee,
        orgId: this.$orgId
      }

      this.$_respondApprovalRequest(payload).then(({ data }) => {
        this.$toasted.success(data.message, { duration: 3000 })
        this.$router.back({ name: "MyApprovals" })
        this.isSubmitting = false
      }).catch((error) => {
        this.$toasted.error(error.message, { duration: 3000 })
        this.isSubmitting = false
      })
    },

    async getMyApprovalRequests(payload){
      await this.$_getMyApprovalRequests(payload).then(({ data }) => {
        this.submittedBy = _.find(data.approvals, approval =>
          approval.requestId === this.$route.params.id
        )
      })
    },
    async getApprovalProgress(requestId){
      await this.$_getApprovalProgress(requestId).then(({ data }) => {
          data.data.filter(a => {
             if ((a.requestId === this.$route.params.id) && (a.isApprovalForAll === 'false')) {
              if (a.userId === this.$AuthUser.id) {
                this.approversList = a;
              }
              this.approversNumber = a.length;
            }
            else if ((a.requestId === this.$route.params.id) && (a.isApprovalForAll === 'true')) {
                if (a.currentApprover === "active") {
                  this.approversList = a;
                  this.approversNumber = a.length;
                }
              }
            return {};
          });

        this.approverInfo = _.find(data.data, approver =>
          approver.currentApprover === "active" &&
          approver.requestId === this.$route.params.id
        )
      })
    },
    async getDelegatedApprovalRequest(requestId){
      await this.$_getEmployeeDelegationDetails(requestId).then(({ data }) => {
        this.delegatorInfo = data.delegatorInfo
        this.delegateeInfo = data.delegatee
        this.delegatedEmployee = data.delegatedEmployee
      })
    },
  },
  async created() {
    this.isLoading = true
    await this.getMyApprovalRequests({ userId: this.$AuthUser.id }, {params : '', paginate: "?page=1&perPage=1000", module: '&platform=ess'})
    await this.getDelegatedApprovalRequest(this.$route.params.id)
    await this.getApprovalProgress(this.$route.params.id)
    this.isLoading = false
  },
};
</script>

<style scoped>
.text-10{
  font-size: 10px !important;
}
.button-class {
  padding: 10px 40px !important;
  font-weight: 600;
  display: flex;
  justify-self: end;
}
.pending {
  background: rgba(233, 147, 35, 0.08);
  color: #e99323;
  text-align: center;
  padding: 8px;
  border-radius: 4px;
}
.approved {
  background: rgba(19, 181, 106, 0.08);
  color: #13b56a;
  text-align: center;
  padding: 8px;
  border-radius: 4px;
}
.disapproved {
  background: rgba(234, 60, 83, 0.08);
  color: #ea3c53;
  text-align: center;
  padding: 8px;
  border-radius: 4px;
}
.not-started {
  background: #F6F8F9;
  color: #9BA0A7;
  text-align: center;
  padding: 8px;
  border-radius: 4px;
}
</style>
<style>
.rightSidebar {
  width: 520px !important;
}

.btn-bg {
    background-color: var(--dynamic-Background) !important;
 }

.btn-border{
  color: var(--dynamic-Background) !important;
  border: 1px solid var(--dynamic-Background) !important;
}
</style>
